<template>
  <tr>
    <td class="text-center">
      <h6 class="mt-2">{{ number + 1 }}</h6>
    </td>
    <td>
      <validation-provider name="Başlık" :rules="{ required: true }" v-slot="validationContext">
        <b-form-input
          size="sm"
          class="rounded-0"
          ref="baslik"
          v-model="item.icerik[defaultDil].baslik"
          :state="getValidationState(validationContext)"
          aria-describedby="input-1-live-feedback"
          placeholder="Başlık"
        />
      </validation-provider>
    </td>
    <td>
      <v-select
        v-model="item.turu"
        :options="UrunOzellikTurleri"
        :reduce="(turler) => turler.value"
        label="title"
        :clearable="false"
        class="invoice-filter-select d-block select-size-sm"
      />
    </td>
    <td>
      <b-form-input size="sm" class="rounded-0" ref="baslik" v-model="item.icon" placeholder="İcon" />
    </td>
    <td>
      <b-button size="sm" class="rounded-0" variant="outline-danger" @click="$emit('remove', number)">
        <i class="fad fa-trash" />
      </b-button>
    </td>
  </tr>
</template>

<script>
import store from '@/store';
import vSelect from 'vue-select';
import { ref, defineComponent } from '@vue/composition-api';

export default defineComponent({
  components: { vSelect },
  props: {
    number: [Number],
    item: { type: Object, required: true },
    defaultDil: [String],
  },
  setup() {
    const expo = {};

    expo.UrunOzellikTurleri = ref([
      {
        value: 'input',
        title: 'İnput Form',
      },
      {
        value: 'checkbox',
        title: 'Checkbox Form',
      },
    ]);

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
